import React, { useEffect } from 'react';
import styled from 'styled-components';
import { loadScript } from 'src/helpers/loadScript';

const Container = styled.div`
  text-align: left;
`;

export const JobBoard = () => {
  useEffect(() => {
    loadScript('https://jobs.ashbyhq.com/Rasa/embed');
  }, []);

  return (
    <Container>
      <div id="ashby_embed" />
    </Container>
  );
};
