import React from 'react';
import { Section } from 'src/components/molecules/Section';
import { JobBoard } from 'src/containers/JobBoard';

export default function JobBoardSection() {
  return (
    <Section white id="jobs" centered>
      <h2>Open Positions</h2>

      <JobBoard />
    </Section>
  );
}
